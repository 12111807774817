// Here you can add other styles
.table {
    td {
        vertical-align: middle;
    }
}

.can-click {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.w-95 {
    width: 95%;
}

.w-90 {
    width: 90%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.font-dohyeon {
    font-family: 'Do Hyeon', sans-serif;
}

.font-sunflower {
    font-family: 'Sunflower', sans-serif;
}

.font-sarpanch {
    font-family: 'Sarpanch', sans-serif;
}

.react-date-picker {
    &__wrapper {
        border: none !important;
    }
}

.text-decoration-underline {
    text-decoration: underline;
}

// Do Hyeon, font-family: 'Do Hyeon', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap');

// Sunflower, font-family: 'Sunflower', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Sunflower:wght@300&display=swap');

// Sarpanch font-family: 'Sarpanch', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Sarpanch:wght@600&display=swap');

// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';
